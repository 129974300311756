import { rules } from '@coxy/react-validator'
import isValidAmount from 'utils/is-valid-amount'
import { ICurrency, ICurrencyWithBalance } from 'store/fetchers/currencies'

import { formatNumber } from '../utils/formatters'

export type IRules = Array<{ rule: (value: string) => boolean; message: string }>

export default {
  exchangeDepositAmountLanding: (t: (key: any) => any): IRules => [
    {
      rule: (amount) => amount !== '',
      message: t('deposit_amount_required'),
    },
    {
      rule: (val) => isValidAmount(parseFloat(val)),
      message: t('collateral_amount_required'),
    },
    // {
    //   rule: (val) => {
    //     if (from?.loan_deposit_min_amount) {
    //       return Number(val) >= Number(from?.loan_deposit_min_amount)
    //     }
    //     return true
    //   },
    //   message: `Minimum amount is ${from?.loan_deposit_min_amount} ${from?.code}`,
    // },
  ],
  exchangeDepositAmount: (from: ICurrencyWithBalance, t: (key: any) => any): IRules => [
    {
      rule: (amount) => amount !== '',
      message: t('deposit_amount_required'),
    },
    {
      rule: (val) => isValidAmount(parseFloat(val)),
      message: t('collateral_amount_required'),
    },
    // {
    //   rule: (val) => {
    //     if (from?.loan_deposit_min_amount) {
    //       return Number(val) >= Number(from?.loan_deposit_min_amount)
    //     }
    //     return true
    //   },
    //   message: `Minimum amount is ${from?.loan_deposit_min_amount} ${from?.code}`,
    // },
    {
      rule: (val) => {
        if (from?.balance >= 0) {
          return Number(val) <= Number(from?.balance || 0)
        }
        return true
      },
      message: t('not_enough_balance_for_exchange'),
    },
  ],

  depositAmount: (
    from: ICurrencyWithBalance,
    t: (key: any) => any,
    replacePlaceholders: (text: any, variables: any) => any,
  ): IRules => [
    {
      rule: (amount) => amount !== '',
      message: t('deposit_amount_required'),
    },
    {
      rule: (val) => isValidAmount(parseFloat(val)),
      message: t('collateral_amount_required'),
    },
    {
      rule: (val) => {
        if (from?.loan_deposit_max_amount) {
          return Number(val) <= Number(from?.loan_deposit_max_amount)
        }
        return true
      },
      message: replacePlaceholders(t('max_amount_is'), {
        amount: `${formatNumber(from?.loan_deposit_max_amount)} ${from?.code}`,
      }),
    },
  ],

  loanAmount: (
    to: ICurrency,
    t: (key: any) => any,
    replacePlaceholders: (text: any, variables: any) => any,
  ): IRules => [
    {
      rule: (amount) => amount !== '',
      message: t('loan_amount_required'),
    },
    {
      rule: (val) => isValidAmount(parseFloat(val)),
      message: t('loan_amount_invalid'),
    },
    {
      rule: (val) => {
        if (to?.loan_receive_min_amount) {
          return Number(val) >= Number(to?.loan_receive_min_amount)
        }
        return true
      },
      message: replacePlaceholders(t('min_amount_is_amount'), {
        amount: `${formatNumber(to?.loan_receive_min_amount)} ${to?.code}`,
      }),
    },
    {
      rule: (val) => {
        if (to?.loan_receive_max_amount) {
          return Number(val) <= Number(to?.loan_receive_max_amount)
        }
        return true
      },
      message: replacePlaceholders(t('max_amount_is'), {
        amount: `${formatNumber(to?.loan_receive_max_amount)} ${to?.code}`,
      }),
    },
  ],

  increaseDepositEarn: (
    max: number,
    coin: string,
    t: (key: any) => any,
    replacePlaceholders: (text: any, variables: any) => any,
  ): IRules => [
    {
      rule: (value) => value !== '',
      message: t('increase_amount_required'),
    },
    {
      rule: isValidAmount,
      message: t('increase_amount_invalid'),
    },
    {
      rule: (value) => Number(value) >= 100,
      message: replacePlaceholders(t('min_amount_deposit_is'), { coin }),
    },
    {
      rule: (amount) => Number(amount) <= max,
      message: replacePlaceholders(t('max_amount_increase_is'), { amount: `${formatNumber(max)} ${coin}` }),
    },
  ],

  inputWallet: (t: (key: any) => any): IRules => [
    {
      rule: (value) => value !== '',
      message: t('deposit_amount_required'),
    },
    {
      rule: isValidAmount,
      message: t('deposit_amount_invalid'),
    },
    {
      rule: (value) => {
        const numericValue = Number(value)
        return !isNaN(numericValue) && numericValue > 0
      },
      message: t('increase_deposit_amount'),
    },
  ],

  sendWallet: (balance: number, t: (key: any) => any): IRules => [
    {
      rule: (value) => value !== '',
      message: t('amount_required'),
    },
    {
      rule: isValidAmount,
      message: t('amount_invalid'),
    },
    {
      rule: (value) => {
        const numericValue = Number(value)
        return !isNaN(numericValue) && numericValue > 0
      },
      message: t('amount_must_be_greater_than_0'),
    },
    {
      rule: (value) => {
        const numericValue = Number(value)
        return !isNaN(numericValue) && balance >= numericValue
      },
      message: t('insufficient_funds'),
    },
  ],

  increaseDeposit: (
    max: string,
    from: ICurrency,
    t: (key: any) => any,
    replacePlaceholders: (text: any, variables: any) => any,
  ): IRules => [
    {
      rule: (amount) => amount !== '',
      message: t('increase_amount_required'),
    },
    {
      rule: isValidAmount,
      message: t('increase_amount_invalid'),
    },
    {
      rule: (amount) => !max || Number(amount) <= Number(max),
      message: replacePlaceholders(t('max_amount_increase_is'), { amount: `${formatNumber(max)} ${from?.code}` }),
    },
  ],

  withdrawal: (
    max: string,
    coin: string,
    t: (key: any) => any,
    replacePlaceholders: (text: any, variables: any) => any,
  ): IRules => [
    {
      rule: (amount) => amount !== '',
      message: t('withdrawal_amount_required'),
    },
    {
      rule: isValidAmount,
      message: t('withdrawal_amount_invalid'),
    },
    {
      rule: (value) => Number(value) <= Number(max),
      message: replacePlaceholders(t('max_amount_withdrawal_is'), { amount: `${formatNumber(max)} ${coin}` }),
    },
  ],

  email: rules.email,

  bool: (t: (key: any) => any, replacePlaceholders: (text: any, variables: any) => any, alias = 'Value'): IRules => [
    {
      rule: (value) => !!value,
      message: replacePlaceholders(t('alias_required'), { alias }),
    },
  ],

  earn: (
    currency: ICurrency,
    t: (key: any) => any,
    replacePlaceholders: (text: any, variables: any) => any,
  ): IRules => [
    {
      rule: (amount) => amount !== '',
      message: t('deposit_amount_required'),
    },
    {
      rule: (val) => {
        if (currency?.earn_min_amount) {
          return Number(val) >= Number(currency.earn_min_amount)
        }
        return true
      },
      message: replacePlaceholders(t('min_amount_is_amount'), { amount: formatNumber(currency?.earn_min_amount) }),
    },
  ],
}
